import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";

import Frontpage from '../Frontpage';
import Post from '../Post/Post';
import Projects from '../Projects';
import FourOFour from '../404/404';

const client = new ApolloClient({
	uri: 'http://wp.mish.test/',
	cache: new InMemoryCache(),
});

const App = () => (
	<ApolloProvider client={client}>
		<Router>
			<Switch>
				<Route path="/" exact component={Frontpage} />
				<Route path="/projektid" component={Projects} />
				<Route path="/posts/:slug" children={<Post />} />
				<Route path="/projektid/:slug" children={<Post />} />
				<Route component={FourOFour} />
			</Switch>
		</Router>
	</ApolloProvider>
)

export default App;
