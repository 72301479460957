import React, { useState } from 'react';
import Ticker from 'react-ticker';
import { motion } from 'framer-motion';
import PageVisibility from 'react-page-visibility';
import styled from 'styled-components';

import Section from '../Section';
import Logo from './Logo';

const StyledSection = styled(Section)`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0;
	height: 100vh;
`;

const StyledTickerTop = styled.div`
	position: absolute;
	top: 10px;
	left: 0;
	width: 100%;
`;

const StyledTickerBottom = styled.div`
	position: absolute;
	bottom: 10px;
	left: 0;
	width: 100%;
`;

const StyledTickerSpan = styled.span`
	display: inline-block;
	padding: 10px 60px;
`;

const StyledAnchor = styled.a`
	border: 1px solid #000;
	border-radius: 5px;
	padding: 5px 10px;
	color: #000;
	text-decoration: none;
`;

const Hero = () => {
	const [pageIsVisible, setPageIsVisible] = useState(true);

	const handleVisibilityChange = (isVisible) => {
		setPageIsVisible(isVisible)
	}

	return (
		<>
			<PageVisibility onChange={handleVisibilityChange}>
				{pageIsVisible && (
					<>
						<StyledTickerTop>
							<motion.div initial={{opacity: 0}} animate={{opacity: 1}} transition={{duration: 1, delay: 2}}>
								<Ticker>
									{({index}) => (
										<>
											<StyledTickerSpan>
												<StyledAnchor href="mailto:mish@mish.ee">Need a new web?</StyledAnchor>
											</StyledTickerSpan>
										</>
									)}
								</Ticker>
							</motion.div>
						</StyledTickerTop>
						<StyledTickerBottom>
							<motion.div initial={{opacity: 0}} animate={{opacity: 1}} transition={{duration: 1, delay: 2}}>
								<Ticker>
									{({index}) => (
										<StyledTickerSpan>
											<StyledAnchor href="mailto:mish@mish.ee">mish@mish.ee</StyledAnchor>
										</StyledTickerSpan>
									)}
								</Ticker>
							</motion.div>
						</StyledTickerBottom>
					</>
				)}
			</PageVisibility>
			<StyledSection className="hero">
				<Logo className="logo" />
			</StyledSection>
		</>
	);
};


export default Hero;
