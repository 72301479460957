import React from 'react';
import styled from 'styled-components';

class Section extends React.Component {
	render() {
		return <div className={this.props.className}>{this.props.children}</div>;
	}
}

const StyledSection = styled(Section)`
	padding: 30px;
`;

export default StyledSection;
