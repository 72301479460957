import React from 'react';
import Parallax from 'parallax-js';
import { motion } from "framer-motion"
import styled from 'styled-components';

class Logo extends React.Component {
	componentDidMount() {
		this.parallax = new Parallax(this.scene)
	}

	componentWillUnmount() {
		this.parallax.disable()
	}

	render() {
		const animateStart = {
			opacity: 0,
			scale: 0.95,
		}

		const animateEnd = {
			opacity: 1,
			scale: 1,
		}

		const animateTransition = {
			duration: 1.25,
			delay: 1,
		}

		return (
			<svg className={this.props.className} ref={el => this.scene = el} version="1.1" id="Layer_1"
				 xmlns="http://www.w3.org/2000/svg"
				 x="0px" y="0px"
				 viewBox="0 0 719 461" style={{'enable-background': 'new 0 0 719 461'}}>
				<motion.path
					initial={animateStart}
					animate={animateEnd}
					transition={animateTransition}
					d="M148.6,279.4V181h29.1l32.6,66.1h1l32.7-66.1h29v98.4h-22.9v-65.8h-1.3l-30,60.2h-16.4l-30.1-60.2h-1.3v65.8L148.6,279.4
    				L148.6,279.4z"
				/>
				<motion.path
					initial={animateStart}
					animate={animateEnd}
					transition={animateTransition}
					d="M302.1,279.4V181h24.7v98.4L302.1,279.4L302.1,279.4z"
				/>
				<motion.path
					initial={animateStart}
					animate={animateEnd}
					transition={animateTransition}
					d="M546.3,279.4v-41.1h-53.2v41.1h-24.7V181h24.7v39.7h53.2V181H571v98.4L546.3,279.4L546.3,279.4z"
				/>
				<motion.g
					initial={{opacity: 0}}
					animate={{opacity: 1}}
					transition={{duration: 0.5}}
					data-depth="0.2"
				>
					<path style={{fill: 'none', stroke: '#000000', 'stroke-width': 3, 'stroke-miterlimit': 10}} d="M479.6,265.6c0-6.7-1.1-12.6-3.4-17.8
					c-2.3-5.3-5.8-9.8-10.6-13.8c-4.8-4-11-7.4-18.4-10.2c-7.4-2.8-16.4-5.1-26.8-7l-24.9-4.4c-12.1-2.2-20.8-4.8-26-8.1
					c-5.3-3.2-7.9-7.5-7.9-12.8c0-3.2,0.8-6.2,2.6-8.8c1.7-2.7,4.1-4.9,7.3-6.8c3.2-1.9,6.9-3.3,11.3-4.3c4.4-1,9.3-1.5,14.7-1.5
					c5.2,0,10,0.6,14.5,1.6c4.4,1.1,8.3,2.6,11.6,4.6c3.3,2,6,4.4,8.1,7.2c2.1,2.8,3.4,5.9,3.9,9.3h40.5c-0.3-7.7-2.5-14.6-6.4-20.9
					c-4-6.3-9.5-11.8-16.4-16.4c-7-4.6-15.2-8.1-24.7-10.7c-9.5-2.5-19.9-3.8-31.2-3.8c-11.9,0-22.7,1.3-32.4,3.8
					c-9.7,2.5-18,6.2-24.9,10.9c-6.9,4.7-12.1,10.4-15.9,17c-3.8,6.6-5.6,13.9-5.6,22c0,26.2,18.6,42.5,56,49.1l26.8,4.8
					c6.2,1.1,11.7,2.4,16.2,3.7c4.5,1.4,8.2,2.9,11,4.7c2.9,1.8,4.9,3.7,6.2,6c1.3,2.2,2,4.7,2,7.4h42.8
					C479.5,268.6,479.6,267.2,479.6,265.6z" />
					<path
						d="M477.9,291.7v25h-39.5v-25H477.9 M435.3,288.6V319v0.7h45.5v-31h-0.2h-45.3V288.6L435.3,288.6z" />
				</motion.g>
			</svg>
		);
	}
};

const StyledLogo = styled(Logo)`
	height: 100%;
`;

export default StyledLogo;
